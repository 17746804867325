// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView } from 'react-native-ui-lib';
import DynamicForm, { CustomDatePicker, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, Label, PrimaryButton, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App } from "antd"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Icon } from "react-native-eva-icons"
import { HeaderResearch } from "./PaperListingScreen"
import { Path, Svg } from "react-native-svg"

const SearchScreen = observer(function SearchScreen(props) {
    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }


    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const [searchTerm, setSearchTerm] = useState("");
    const [category_name, setCategoryName] = useState("");
    const [year, setYear] = useState(null);
    const [authors_name, setAuthorsName] = useState("");
    const [minimum_citation_counts, setMinimumCitationCounts] = useState("");
    const email = GlobalMasterStore((state) => state.globalData.email);

    const [suggested_searches, setSuggestedSearches] = useState([]);

    const getUser = async () => {
        console.log("email", email);

        try {
            const response = await api.customRoute("get-user", {
                email: email,
            });

            if (response.user.relavant_search_terms) {
                setSuggestedSearches(response.user.relavant_search_terms);
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        getUser();
    }, [email]);

    return (<View center style={{
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
            }}>
                <View row style={{
                    width: "100%",
                    height: Dimensions.get('window').height,
                }}>

                    <View flexG
                        style={{
                            width: "100%",
                            height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >

                        <View >
                            <HeaderResearch navigationFn={navigationFn} showPaperCount={true} />

                            <View padding-20 marginT-40 center>
                                <View row>
                                    <Text style={{
                                        fontSize: 52,
                                        fontWeight: 'bold',
                                        marginBottom: 10,
                                    }}>Over</Text>
                                    <Text style={{
                                        fontSize: 52,
                                        fontWeight: 'bold',
                                        marginBottom: 10,
                                        color: "#6e0d81",
                                        marginHorizontal: 10,
                                    }}>50 Million Research Papers</Text>
                                    <Text style={{
                                        fontSize: 52,
                                        fontWeight: 'bold',
                                        marginBottom: 10,
                                    }}>To Work With</Text>
                                </View>

                                <Text style={{
                                    fontSize: 28,
                                    marginBottom: 20,
                                }}>Start your research journey with us</Text>

                                <Card marginT-20 style={{
                                    padding: 10,
                                    // background: #ffffff85;
                                    // border-radius: 16px;
                                    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                                    // backdrop-filter: blur(5px);
                                    // -webkit-backdrop-filter: blur(5px);
                                    // border: 1px solid rgba(255, 255, 255, 0.3);
                                    width: "90%",
                                    backgroundColor: "#ffffff85",
                                    borderRadius: 16,
                                    shadowColor: "#000",
                                    shadowOffset: {
                                        width: 0,
                                        height: 4,
                                    },
                                    shadowOpacity: 0.1,
                                    shadowRadius: 30,
                                    elevation: 5,
                                    backdropFilter: "blur(5px)",
                                    WebkitBackdropFilter: "blur(5px)",
                                    border: "1px solid rgba(255, 255, 255, 0.3)",
                                }}>
                                    <View row>
                                        <View flex row centerV marginL-10 padding-10 style={{
                                            borderBottomColor: '#00000045',
                                            borderBottomWidth: 1,
                                        }}>
                                            <View row flex centerV>
                                                <Svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="icon"
                                                    style={{
                                                        width: "20px",
                                                        verticalAlign: "middle",
                                                        fill: "currentColor",
                                                        overflow: "hidden",
                                                    }}
                                                    viewBox="0 0 1024 1024"
                                                >
                                                    <Path d="M204.8 341.33v136.54h68.27V409.6h68.26v341.34h-68.26v68.26h204.8v-68.26H409.6V409.6h68.27v68.27h68.26V341.33zm614.4-68.26V204.8c-41.001 0-77.373 18.463-102.4 47.104-25.027-28.631-61.399-47.104-102.4-47.104v68.27a68.27 68.27 0 0 1 68.27 68.27v204.8H614.4v68.26h68.27v68.26a68.28 68.28 0 0 1-68.27 68.28v68.26c41.001 0 77.373-18.463 102.4-47.104 25.027 28.641 61.399 47.104 102.4 47.104v-68.26a68.28 68.28 0 0 1-68.27-68.28V614.4h68.27v-68.26h-68.27v-204.8a68.27 68.27 0 0 1 68.27-68.27z" />
                                                </Svg>
                                                <View style={{
                                                    flex: 1,
                                                }}>
                                                    <TextField
                                                        placeholder="Search"
                                                        placeholderTextColor="#00000045"
                                                        style={{
                                                            flex: 1,
                                                            height: 40,
                                                            color: "#000",
                                                            marginLeft: 10,
                                                            fontSize: 14,
                                                        }}
                                                        onChangeText={(value) => {
                                                            setSearchTerm(value);
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'Enter') {
                                                                navigationFn("search-results", {
                                                                    search: searchTerm,
                                                                    category_name: category_name || "",
                                                                    year: year || "",
                                                                    authors_name: authors_name || "",
                                                                    minimum_citation_counts: minimum_citation_counts || "",
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                            <TouchableOpacity onPress={() => {
                                                navigationFn("search-results", {
                                                    search: searchTerm,
                                                    category_name: category_name || "",
                                                    year: year || "",
                                                    authors_name: authors_name || "",
                                                    minimum_citation_counts: minimum_citation_counts || "",
                                                });
                                            }}>
                                                <View style={{
                                                    backgroundColor: "#20303c",
                                                    borderRadius: 80,
                                                    padding: 10,
                                                    marginLeft: 10,
                                                }}>
                                                    <Icon name="search-outline" width={16} height={16} fill="white" />
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <View centerH>
                                        <View center padding-10 marginV-10 style={{
                                            width: "100%",
                                        }}>
                                            <View style={{
                                                width: "100%",
                                            }}>
                                                <View left row centerV>
                                                    <View row centerV marginR-10 style={{
                                                        flex: 1,
                                                    }}>
                                                        <View flex>
                                                            <View row centerV marginB-10>
                                                                {/* <Icon name="calendar-outline" width={18} height={18} fill="#20303c" /> */}

                                                                <Svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    stroke="#20303c"
                                                                    strokeWidth={1.5}
                                                                    width={18}
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <Path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                                                                    />
                                                                </Svg>
                                                                <Text marginL-5 style={{
                                                                    fontSize: 14,
                                                                    color: "#20303c",
                                                                    fontWeight: 'bold',
                                                                }}>Publication Year</Text>
                                                            </View>

                                                            <CustomSelect
                                                                value={year}
                                                                placeholder="Published At"
                                                                options={[
                                                                    { label: "2024", value: 2024 },
                                                                    { label: "2023", value: 2023 },
                                                                    { label: "2022", value: 2022 },
                                                                    { label: "2021", value: 2021 },
                                                                    { label: "2020", value: 2020 },
                                                                    { label: "2019", value: 2019 },
                                                                    { label: "2018", value: 2018 },
                                                                    { label: "2017", value: 2017 },
                                                                    { label: "2016", value: 2016 },
                                                                    { label: "2015", value: 2015 },
                                                                    { label: "2014", value: 2014 },
                                                                    { label: "2013", value: 2013 },
                                                                    { label: "2012", value: 2012 },
                                                                    { label: "2011", value: 2011 },
                                                                    { label: "2010", value: 2010 },
                                                                    { label: "2009", value: 2009 },
                                                                    { label: "2008", value: 2008 },
                                                                    { label: "2007", value: 2007 },
                                                                    { label: "2006", value: 2006 },
                                                                    { label: "2005", value: 2005 },
                                                                    { label: "2004", value: 2004 },
                                                                    { label: "2003", value: 2003 },
                                                                    { label: "2002", value: 2002 },
                                                                    { label: "2001", value: 2001 },
                                                                    { label: "2000", value: 2000 },
                                                                ]}
                                                                onChange={(value) => {
                                                                    setYear(value);
                                                                }}
                                                                allowClear={true}
                                                            />
                                                        </View>
                                                    </View>

                                                    <View row centerV marginR-10 style={{
                                                        flex: 1,
                                                    }}>
                                                        {/* <View backgroundColor="#f0f0f0" marginR-10 style={{
                                                            borderRadius: 80,
                                                            padding: 10
                                                        }}>
                                                            <Icon name="hash-outline" width={40} height={40} fill="#000000" />
                                                        </View> */}
                                                        <View flex>
                                                            <View row centerV marginB-10>
                                                                {/* <Icon name="hash-outline" width={18} height={18} fill="#20303c" /> */}

                                                                <Svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    stroke="#20303c"
                                                                    strokeWidth={1.5}
                                                                    width={18}
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <Path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                                                                    />
                                                                </Svg>
                                                                <Text marginL-5 style={{
                                                                    fontSize: 14,
                                                                    color: "#20303c",
                                                                    fontWeight: 'bold',
                                                                }}>Category</Text>
                                                            </View>
                                                            <CustomSelect
                                                                value={category_name}
                                                                placeholder="Category"
                                                                options={[
                                                                    {
                                                                        "id": "https://openalex.org/fields/27",
                                                                        "display_name": "Medicine"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/33",
                                                                        "display_name": "Social Sciences"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/22",
                                                                        "display_name": "Engineering"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/12",
                                                                        "display_name": "Arts and Humanities"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/17",
                                                                        "display_name": "Computer Science"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/13",
                                                                        "display_name": "Biochemistry, Genetics and Molecular Biology"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/11",
                                                                        "display_name": "Agricultural and Biological Sciences"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/23",
                                                                        "display_name": "Environmental Science"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/31",
                                                                        "display_name": "Physics and Astronomy"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/25",
                                                                        "display_name": "Materials Science"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/14",
                                                                        "display_name": "Business, Management and Accounting"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/36",
                                                                        "display_name": "Health Professions"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/20",
                                                                        "display_name": "Economics, Econometrics and Finance"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/32",
                                                                        "display_name": "Psychology"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/16",
                                                                        "display_name": "Chemistry"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/19",
                                                                        "display_name": "Earth and Planetary Sciences"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/28",
                                                                        "display_name": "Neuroscience"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/26",
                                                                        "display_name": "Mathematics"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/24",
                                                                        "display_name": "Immunology and Microbiology"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/18",
                                                                        "display_name": "Decision Sciences"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/21",
                                                                        "display_name": "Energy"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/29",
                                                                        "display_name": "Nursing"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/30",
                                                                        "display_name": "Pharmacology, Toxicology and Pharmaceutics"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/35",
                                                                        "display_name": "Dentistry"
                                                                    },
                                                                    {
                                                                        "id": "https://openalex.org/fields/15",
                                                                        "display_name": "Chemical Engineering"
                                                                    }
                                                                ].map((item) => {
                                                                    return {
                                                                        label: item.display_name,
                                                                        value: item.id,
                                                                    }
                                                                })}
                                                                onChange={(value) => {
                                                                    setCategoryName(value);
                                                                }}
                                                                allowClear={true}
                                                            />
                                                        </View>
                                                    </View>

                                                    <View marginR-10 style={{
                                                        flex: 1,
                                                    }}>
                                                        <View row centerV marginR-10 style={{
                                                            flex: 1,
                                                        }}>
                                                            <View flex>
                                                                <View row centerV marginB-10>
                                                                    {/* <Icon name="trending-up-outline" width={18} height={18} fill="#20303c" /> */}

                                                                    <Svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={16}
                                                                        height={16}
                                                                        fill="none"
                                                                        viewBox="0 0 20 20"
                                                                    >
                                                                        <Path
                                                                            fill="#20303c"
                                                                            d="M5.224 10.33c.424.043 1.356.043 1.356.64 0 .811-1.186 1.878-3.092 3.03-.593.385-1.821.982-1.821 1.793 0 .64.466 1.11 1.44.982.932-.128 2.372-.769 4.277-2.39 1.99-1.665 3.854-4.439 3.854-7.17 0-2.774-1.863-5.548-4.87-5.548-2.414 0-4.49 1.835-4.49 4.353 0 1.92 1.314 4.14 3.346 4.31Z"
                                                                        />
                                                                        <Path
                                                                            fill="#20303c"
                                                                            d="M11.178 11.53c.348.184.73.303 1.141.338.424.043 1.356.043 1.356.64 0 .81-1.186 1.878-3.092 3.03-.593.384-1.821.982-1.821 1.793 0 .64.466 1.11 1.44.981.932-.128 2.372-.768 4.277-2.39 1.99-1.664 3.854-4.438 3.854-7.17 0-2.774-1.863-5.548-4.87-5.548-.707 0-1.385.158-1.99.444a7.481 7.481 0 0 1 .91 3.567c0 1.524-.488 3.002-1.205 4.315Z"
                                                                        />
                                                                    </Svg>
                                                                    <Text
                                                                        marginL-5
                                                                        style={{
                                                                            fontSize: 14,
                                                                            color: "#000000",
                                                                            fontWeight: 'bold',
                                                                        }}>Minimum Citation Counts</Text>
                                                                </View>
                                                                <CustomNumberInput
                                                                    value={minimum_citation_counts}
                                                                    placeholder="Min Citation Counts"
                                                                    onChange={(value) => {
                                                                        setMinimumCitationCounts(value);
                                                                    }}
                                                                />
                                                            </View>
                                                        </View>
                                                    </View>

                                                    <View style={{
                                                        flex: 1,
                                                    }}>
                                                        <View row centerV marginR-10 style={{
                                                            flex: 1,
                                                        }}>
                                                            {/* <View backgroundColor="#f0f0f0" marginR-10 style={{
                                                                borderRadius: 80,
                                                                padding: 10
                                                            }}>
                                                                <Icon name="person-outline" width={40} height={40} fill="#000000" />
                                                            </View> */}



                                                            <View flex>
                                                                <View row centerV marginB-10>
                                                                    {/* <Icon name="edit-outline" width={18} height={18} fill="#20303c" /> */}
                                                                    <Svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="icon"
                                                                        style={{
                                                                            width: 20,
                                                                        }}
                                                                        viewBox="0 0 1024 1024"
                                                                    >
                                                                        <Path
                                                                            fill="#20303c"
                                                                            d="M673.579 584.96c-11.734 0-25.046 0-39.168 2.005 46.848 33.963 79.573 78.806 79.573 139.392V827.35h242.347V726.357c0-94.122-188.587-141.397-282.752-141.397m-323.2 0c-94.123 0-282.795 47.275-282.795 141.397V827.35h565.59V726.357c0-94.122-188.63-141.397-282.795-141.397m0-80.81a121.173 121.173 0 0 0 121.216-121.174A121.173 121.173 0 0 0 350.42 261.803a121.173 121.173 0 0 0-121.216 121.173A121.173 121.173 0 0 0 350.38 504.149m323.2 0a121.173 121.173 0 0 0 121.173-121.173 121.173 121.173 0 0 0-121.173-121.173 121.173 121.173 0 0 0-121.174 121.173A121.173 121.173 0 0 0 673.58 504.149z"
                                                                        />
                                                                    </Svg>
                                                                    <Text
                                                                        marginL-5
                                                                        style={{
                                                                            fontSize: 14,
                                                                            color: "#000000",
                                                                            fontWeight: 'bold'
                                                                        }}>Author Name</Text>
                                                                </View>
                                                                <CustomTextField
                                                                    placeholder="Authors"
                                                                    value={authors_name}
                                                                    onChange={(value) => {
                                                                        setAuthorsName(value);
                                                                    }}
                                                                />
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </Card>

                                <View marginT-30 style={{ width: "90%" }} center>
                                    <View style={{
                                        position: "relative",
                                        width: "100%",
                                    }}>
                                        <View center>
                                            <View style={{
                                                backgroundColor: "#f9f9f9",
                                                marginBottom: 10,
                                                marginTop: 20,
                                                paddingHorizontal: 20,
                                            }}>
                                                <Text style={{
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    color: "#20303c",
                                                    textTransform: "uppercase",
                                                    textAlign: "center",
                                                    letterSpacing: 2,
                                                }}>Recommended Searches Specially For You</Text>
                                            </View>
                                        </View>

                                        <View style={{
                                            width: "100%",
                                            height: 1,
                                            backgroundColor: "#20303c",
                                            position: "absolute",
                                            top: 28,
                                            left: 0,
                                            zIndex: -1,
                                            opacity: 0.05,
                                        }}></View>
                                    </View>

                                    <View row marginT-20 style={{
                                        flexWrap: "wrap",
                                        maxWidth: "100%",
                                        paddingHorizontal: 25,
                                    }}>
                                        {suggested_searches.map((search) => {
                                            return (
                                                <TouchableOpacity key={search} onPress={() => {
                                                    setSearchTerm(search);
                                                    navigationFn("search-results", {
                                                        search: search,
                                                        category_name: category_name || "",
                                                        year: year || "",
                                                        authors_name: authors_name || "",
                                                        minimum_citation_counts: minimum_citation_counts || "",
                                                    });
                                                }}
                                                    style={{
                                                        marginRight: 10,
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    <View style={{
                                                        backgroundColor: "#20303c",
                                                        borderRadius: 80,
                                                        padding: 10,
                                                        paddingHorizontal: 20,
                                                    }}>
                                                        <Text style={{
                                                            color: "white",
                                                        }}>{search}</Text>
                                                    </View>
                                                </TouchableOpacity>
                                            );
                                        })}
                                    </View>
                                </View>

                            </View>
                        </View>
                    </View>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    </View >)
})

export default SearchScreen

const $container = {
    flex: 1,
}