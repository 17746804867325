import React from "react"
import { StatusBar } from 'expo-status-bar';
import { Alert, Platform, StyleSheet, Text, TextInput, View } from 'react-native';
import * as SplashScreen from "expo-splash-screen"
import { Assets, Image, Spacings, ThemeManager, Typography } from 'react-native-ui-lib';
import { initialWindowMetrics, SafeAreaProvider } from "react-native-safe-area-context"
import { useFonts } from "expo-font"
import { customFontsToLoad } from "./app/theme";
import { useInitialRootStore } from "./app/models"
import { GlobalMasterStore } from "./app/models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { api, collectionsNames } from "./app/services/api"
import { AppNavigator } from "./app/AppNavigator";
import { LogBox } from "react-native"
import 'react-native-gesture-handler';
import { GestureHandlerRootView } from "react-native-gesture-handler";

// prettier-ignore
LogBox.ignoreLogs([
  /\\(exported as '|':420\\)|PlatformColor/,
  "Require cycle:",
  'Warning:',
  "WARNING",
  "WARN"
])

SplashScreen.preventAutoHideAsync()

Assets.loadAssetsGroup('icons', {
  heart: require('./assets/icons/heart.png'),
  drag: require('./assets/icons/drag.png'),
  cf: require('./assets/icons/svg_cf.svg'),
});

Assets.loadAssetsGroup('images', {
  logo: require('./assets/logo.png'),
  logo_dark: require('./assets/images/logo_dark.png'),
  login_bg: require('./assets/images/login_bg.png'),
  research_bg_blur: require('./assets/images/HomeNew.jpg'),
  research_bg: require('./assets/images/HomeNewUnblur.jpg'),
  rank1: require('./assets/images/rank1.png'),
  rank2: require('./assets/images/rank2.png'),
  rank3: require('./assets/images/rank3.png'),
  rank4: require('./assets/images/rank4.png'),
  rank5: require('./assets/images/rank5.png'),
  rank6: require('./assets/images/rank6.png'),
  rank7: require('./assets/images/rank7.png'),
  rank8: require('./assets/images/rank8.png'),
  rank9: require('./assets/images/rank9.png'),
  rank10: require('./assets/images/rank10.png'),
  chat_vector: require('./assets/chat_vector.png'),
});

Assets.loadAssetsGroup('taqwa', {
  announcements: require('./assets/taqwa/announcements.png'),
  reports: require('./assets/taqwa/reports.png'),
  challenges: require('./assets/taqwa/challenges.png'),
  dashboard: require('./assets/taqwa/dashboard.png'),
  employees: require('./assets/taqwa/employees.png'),
  field_work: require('./assets/taqwa/field_work.png'),
  leads: require('./assets/taqwa/leads.png'),
  meetings: require('./assets/taqwa/meetings.png'),
  trainings: require('./assets/taqwa/trainings.png'),
  master: require('./assets/taqwa/master.png'),
  employee_bg: require('./assets/taqwa/employee_bg.png'),
  CourseBG: require('./assets/taqwa/CourseBG.png'),
});

Assets.loadAssetsGroup('HomeIcons', {
  Announcements: require('./assets/taqwa/HomeIcons/Announcements.png'),
  Attendance: require('./assets/taqwa/HomeIcons/Attendance.png'),
  Challenges: require('./assets/taqwa/HomeIcons/Challenges.png'),
  Contract: require('./assets/taqwa/HomeIcons/Contract.png'),
  DailyActivites: require('./assets/taqwa/HomeIcons/DailyActivites.png'),
  Leads: require('./assets/taqwa/HomeIcons/Leads.png'),
  Meetings: require('./assets/taqwa/HomeIcons/Meetings.png'),
  Training: require('./assets/taqwa/HomeIcons/Training.png')
});

Assets.loadAssetsGroup('sidebarIcons', {
  heart: require('./assets/icons/heart.png'),
  drag: require('./assets/icons/drag.png'),
  cf: require('./assets/icons/svg_cf.svg'),
});

ThemeManager.setComponentTheme('View', {
  pointer: {
    cursor: 'pointer',
  },
});

ThemeManager.setComponentTheme('Button', {
  size: 'small',
  borderRadius: 4,
  backgroundColor: '#1084f8',
  style: { paddingTop: 5, paddingBottom: 8, paddingLeft: 30, paddingRight: 30 },
});

Typography.loadTypographies({
  h1: { fontSize: 18, fontWeight: '500', lineHeight: 28, color: 'rgb(57, 65, 70)', fontFamily: 'SourceSansProBold' },
  h2: { fontSize: 22, fontWeight: '300', lineHeight: 28 },
  tabHeading: { fontSize: 13, fontWeight: '500', textTransform: 'uppercase', lineHeight: 16, color: '#979899', letterSpacing: 0.3 },
  value: { fontSize: 14, fontWeight: '500', lineHeight: 20, color: '#324054', letterSpacing: 0.3 },
  formlabel: { flexWrap: 'wrap', fontSize: 14, letterSpacing: 0.4, fontWeight: "500", color: "#000000", lineHeight: 18, fontFamily: 'SourceSansProSemiBold' },
  SourceSansProRegular: { fontFamily: 'SourceSansProRegular' },
  bold: { fontFamily: 'SourceSansProSemiBold' },
  lightCardTitle: { color: '#878686' },
  darkCardTitle: { fontFamily: 'SourceSansProSemiBold' },
  monoLight: { fontFamily: 'JetBrainsMonoLight' },
  monoRegular: { fontFamily: 'JetBrainsMonoRegular' },
  monoMedium: { fontFamily: 'JetBrainsMonoMedium' },
  monoSemiBold: { fontFamily: 'JetBrainsMonoSemiBold' },
  monoBold: { fontFamily: 'JetBrainsMonoBold' },
  monoTableBody: { fontFamily: 'JetBrainsMonoRegular', fontSize: 12 },
  monoTableHeading: { fontFamily: 'JetBrainsMonoExtraBold', fontSize: 11 },
  link: { color: '#1084f8' },
  white: { color: '#ffffff' },
  homeCardText: { color: '#979899', fontSize: 11, letterSpacing: 0.3 }
});

ThemeManager.setComponentTheme('Text', {
  SourceSansProRegular: true,
});

export default function App() {

  Text.defaultProps = Text.defaultProps || {};
  Text.defaultProps.allowFontScaling = false;
  TextInput.defaultProps = TextInput.defaultProps || {};
  TextInput.defaultProps.allowFontScaling = false;

  const hideSplashScreen = SplashScreen.hideAsync;
  const [areFontsLoaded] = useFonts(customFontsToLoad)

  const token = GlobalMasterStore((state) => state.token);
  const setToken = GlobalMasterStore((state) => state.setToken);
  const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);

  const { rehydrated } = useInitialRootStore(async () => {
    // async function onFetchUpdateAsync() {
    //   try {
    //     const update = await Updates.checkForUpdateAsync();

    //     if (update.isAvailable) {
    //       Alert.alert(
    //         'Update available',
    //         'An update is available. Would you like to update the app?',
    //         [
    //           { text: 'Cancel', style: 'cancel' },
    //           {
    //             text: 'OK', onPress: async () => {
    //               await Updates.fetchUpdateAsync();
    //               await Updates.reloadAsync();
    //             }
    //           },
    //         ],
    //         { cancelable: false }
    //       );
    //     }
    //   } catch (error) {
    //     console.log(`Error fetching latest Expo update: ${error}`);
    //   }
    // }

    // await onFetchUpdateAsync();

    let storedToken = await AsyncStorage.getItem('token');
    let globalData = await AsyncStorage.getItem('globalData');
    let permissions = await AsyncStorage.getItem('permissions');
    let employee = await AsyncStorage.getItem('employee');
    let locations = await AsyncStorage.getItem('locations');

    if (globalData) {
      try {
        globalData = (JSON.parse(globalData));
      } catch (e) {

      }
    }

    if (storedToken && globalData) {
      setToken(storedToken)
      api.setTokenToAPI(storedToken, globalData.organization_id);

      setGlobalData(globalData);
    } else {
      setToken(null);
      api.setTokenToAPI(null, null);
      setGlobalData(null);
    }

    if (permissions) {
      try {
        permissions = (JSON.parse(permissions));
      } catch (e) {

      }
    }

    if (employee) {
      try {
        employee = (JSON.parse(employee));
      } catch (e) {

      }
    }

    if (locations) {
      try {
        locations = (JSON.parse(locations));
      } catch (e) {

      }
    }

    setTimeout(hideSplashScreen, 1000)
  })

  if (!rehydrated || !areFontsLoaded) return null;

  return (
    Platform.OS != 'web' ? <GestureHandlerRootView style={{ flex: 1 }}>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <StatusBar style="auto" />
        <AppNavigator />
      </SafeAreaProvider>
    </GestureHandlerRootView> : <AppNavigator />
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
