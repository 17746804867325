import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { create } from 'zustand'

export const GlobalMasterStore = create((set, get) => ({
    tempLoggedIn: Platform.OS == 'web' ? window?.localStorage?.getItem('tempLoggedIn') ? JSON.parse(window?.localStorage?.getItem('tempLoggedIn')) : false : false,
    setTempLoggedIn: (value) => {
        set((state) => {
            return { tempLoggedIn: value };
        })
        setTimeout(async () => {
            await AsyncStorage.setItem('tempLoggedIn', JSON.stringify(get().tempLoggedIn));
        }, 0);
    },
    permissions: Platform.OS == 'web' ? window?.localStorage?.getItem('permissions') ? JSON.parse(window?.localStorage?.getItem('permissions')) : {} : {},
    setPermissions: (value) => {
        set((state) => {
            return { permissions: { ...state.permissions, ...value } };
        })
        setTimeout(async () => {
            if (get().permissions) {
                await AsyncStorage.setItem('permissions', JSON.stringify(get().permissions));
            }
        }, 0);
    },
    employee: Platform.OS == 'web' ? window?.localStorage?.getItem('employee') ? JSON.parse(window?.localStorage?.getItem('employee')) : {} : {},
    setEmployee: (value) => {
        set((state) => {
            return { employee: { ...value } };
        })
        setTimeout(async () => {
            if (get().employee) {
                await AsyncStorage.setItem('employee', JSON.stringify(get().employee));
            }
        }, 0);
    },
    locations: Platform.OS == 'web' ? window?.localStorage?.getItem('locations') ? JSON.parse(window?.localStorage?.getItem('locations')) : [] : [],
    setLocations: (value) => {
        set((state) => {
            return { locations: value };
        })
        setTimeout(async () => {
            if (get().locations) {
                await AsyncStorage.setItem('locations', JSON.stringify(get().locations));
            }
        }, 0);
    },
    token: Platform.OS == 'web' ? window?.localStorage?.getItem('token') ? window?.localStorage?.getItem('token') : '' : '',
    setToken: (value) => {
        set((state) => {
            return { token: value };
        })
        setTimeout(async () => {
            if (get().token) {
                await AsyncStorage.setItem('token', get().token);
            }
        }, 0);
    },
    globalData: Platform.OS == 'web' ? window?.localStorage?.getItem('globalData') ? JSON.parse(window?.localStorage?.getItem('globalData')) : {} : {},
    setGlobalData: (value) => {
        set((state) => {
            return { globalData: { ...state.globalData, ...value } };
        })
        setTimeout(async () => {
            await AsyncStorage.setItem('globalData', JSON.stringify(get().globalData));
        }, 0);
    },
    cart: Platform.OS == 'web' ? window?.localStorage?.getItem('cart') ? JSON.parse(window?.localStorage?.getItem('cart')) : [] : [],
    setCart: (value) => {
        set((state) => {
            return { cart: value };
        })
        setTimeout(async () => {
            if (get().cart) {
                await AsyncStorage.setItem('cart', JSON.stringify(get().cart));
            }
        }, 0);
    },
    wishList: Platform.OS == 'web' ? window?.localStorage?.getItem('wishList') ? JSON.parse(window?.localStorage?.getItem('wishList')) : [] : [],
    setWishList: (value) => {
        set((state) => {
            return { wishList: value };
        })
        setTimeout(async () => {
            if (get().wishList) {
                await AsyncStorage.setItem('wishList', JSON.stringify(get().wishList));
            }
        }, 0);
    },
}))