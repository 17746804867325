// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView } from 'react-native-ui-lib';
import DynamicForm, { CustomModal, CustomNumberInput, CustomTextField, DangerButton, Label, PrimaryButton, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App } from "antd"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyB9FLfsQFjuRL0tsiAicPWiD8NKIyzoAEA",
    authDomain: "researchist-ff0ed.firebaseapp.com",
    projectId: "researchist-ff0ed",
    storageBucket: "researchist-ff0ed.appspot.com",
    messagingSenderId: "438350205219",
    appId: "1:438350205219:web:f2d0f62da5b3191ac4bc96",
    measurementId: "G-QFYF2S23VE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const LoginScreen = observer(function LoginScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const res = await signInWithPopup(auth, provider);
            console.log(res);
            const email = res.user.email;
            const uid = res.user.uid;
            proceedWithLogin(email, uid);
        } catch (error) {
            console.error(error);
        }
    };

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);

    const token = GlobalMasterStore((state) => state.token);
    const setToken = GlobalMasterStore((state) => state.setToken);

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");
    const [forgotOTP, setForgotOTP] = useState("");
    const [forgotPassword, setForgotPassword] = useState("");
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);

    const [searchTerm, setSearchTerm] = useState("");
    const [category_name, setCategoryName] = useState("");
    const [year, setYear] = useState(null);
    const [authors_name, setAuthorsName] = useState("");
    const [minimum_citation_counts, setMinimumCitationCounts] = useState("");
    const setTempLoggedIn = GlobalMasterStore((state) => state.setTempLoggedIn);

    const login = async (email, password) => {
        if (!email) {
            if (Platform.OS === 'web') {
                message.error("Please enter email");
            } else {
                Alert.alert("Error", "Please enter email");
            }
            return;
        }

        if (!password) {
            if (Platform.OS === 'web') {
                message.error("Please enter password");
            } else {
                Alert.alert("Error", "Please enter password");
            }
            return;
        }

        const auth = getAuth();

        try {
            // Attempt to sign in
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            // If successful, proceed with login
            proceedWithLogin(email, userCredential.user.uid);
        } catch (error) {
            if (error.code === 'auth/invalid-login-credentials') {
                // User doesn't exist, attempt to sign up
                try {
                    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                    // If successful, proceed with login
                    proceedWithLogin(email, userCredential.user.uid);
                } catch (signUpError) {
                    handleAuthError(signUpError);
                }
            } else {
                handleAuthError(error);
            }
        }
    };

    const proceedWithLogin = async (email, uid) => {
        const res = await api.customRoute("create-or-get-user", {
            email: email,
            uid: uid,
        })

        const { user, token } = res;
        api.setTokenToAPI(token, 20);

        setToken(token);
        setGlobalData({
            researchist_id: user._id,
            email: email,
            name: user.name,
            uid: uid
        })

        setTempLoggedIn(true);

        if (user.name) {
            navigationFn("home");
        } else {
            navigationFn("user-onboarding");
        }
    };

    const handleAuthError = (error) => {
        let errorMessage = "An error occurred. Please try again.";

        if (error.code === 'auth/wrong-password') {
            errorMessage = "Incorrect password. Please try again.";
        } else if (error.code === 'auth/invalid-email') {
            errorMessage = "Invalid email address.";
        } else if (error.code === 'auth/weak-password') {
            errorMessage = "Password should be at least 6 characters.";
        } else if (error.code === 'auth/email-already-in-use') {
            errorMessage = "Email is already in use. Please try logging in.";
        }

        if (Platform.OS === 'web') {
            message.error(errorMessage);
        } else {
            Alert.alert("Error", errorMessage);
        }
    };

    const quotes = [{
        text: "We cannot elect a president who provides no hope to the laid-off union worker, no hope for the mother of five and no hope for the researcher who might find a cure for cancer. We cannot elect a leader who is willfully ignorant to the outcry of young people who want real criminal justice reform and responsible gun safety legislation now.",
        author: "Sheila Jackson Lee",
    },
    {
        text: "I'm not a parenting expert. In fact, I'm not sure that I even believe in the idea of 'parenting experts.' I'm an engaged, imperfect parent and a passionate researcher. I'm an experienced mapmaker and a stumbling traveler. Like many of you, parenting is by far my boldest and most daring adventure.",
        author: "Brene Brown",
    },
    {
        text: "I am not a farmer; I am a researcher who studies the plants that come to your dinner table, which means that I ask questions for a living.",
        author: "Hope Jahren",
    }]

    return (<View center style={{
        backgroundColor: "#fff",
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
            }}>
                <View centerH flexG padding-20
                    style={{
                        width: "100%",
                        height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                    }}
                >
                    <View flex style={{
                        width: "100%",
                    }}>
                        <View row flex>
                            <View flex-1 padding-20 centerH centerV>

                                <View style={{
                                    width: 400,
                                }}>
                                    <Text text40 marginB-20>Login or Create a free account</Text>
                                    <Text marginB-20>Your smart assistant for everyday tasks.</Text>


                                    <View style={{
                                        width: "100%",
                                    }}>
                                        {Label({ label: "Email" })}
                                        <CustomTextField type={"email"} value={email} onChange={(value) => {
                                            setEmail(value);
                                        }} />
                                    </View>
                                    <View marginT-5 marginB-10 style={{
                                        width: "100%",
                                    }}>
                                        {Label({ label: "Password" })}
                                        <CustomTextField type="password" value={password} onChange={(value) => {
                                            setPassword(value);
                                        }} />
                                    </View>

                                    <Button label="Sign In" backgroundColor="#000" onPress={() => {
                                        login(email, password);
                                    }} />

                                    <View row center marginB-20 marginT-20 style={{
                                        position: 'relative'
                                    }}>
                                        <View style={{
                                            backgroundColor: 'white',
                                            paddingHorizontal: 10,
                                            zIndex: 1,
                                        }}>
                                            <Text>OR</Text>
                                        </View>

                                        <View style={{
                                            position: 'absolute',
                                            left: 0,
                                            right: 0,
                                            height: 1,
                                            top: '50%',
                                            backgroundColor: '#e3e3e3',
                                        }}></View>
                                    </View>

                                    {/* <Button label="Sign up with Google" backgroundColor="#fff" color="#000" outlineColor="#000" marginB-10 /> */}
                                    <SecondaryButton onPress={() => {
                                        handleGoogleSignIn();
                                    }}>
                                        <View row center>
                                            <img crossorigin="anonymous" srcset="https://img.clerk.com/static/google.svg?width=80 1x,https://img.clerk.com/static/google.svg?width=160 2x" src="https://img.clerk.com/static/google.svg?width=160" class="cl-socialButtonsProviderIcon cl-providerIcon cl-socialButtonsProviderIcon__google cl-providerIcon__google 🔒️ cl-internal-2gzuzc" alt="Sign in with Google" />
                                            <Text marginL-10>Sign up with Google</Text>
                                        </View>
                                    </SecondaryButton>
                                </View>

                            </View>


                            <View flex-1 padding-20 backgroundColor="#f5f5f5" center style={{
                                borderRadius: 10,
                                paddingHorizontal: 80
                            }}>
                                <Image assetGroup="images" assetName="logo" style={{
                                    width: 400,
                                    height: 100,
                                    alignSelf: 'center',
                                    marginBottom: -50,
                                }}
                                    resizeMode="contain"
                                />

                                <dotlottie-player src="https://lottie.host/6f4ee854-3625-4849-8985-e423a0752949/hZWZsrb0zk.json" background="transparent" speed="1" style={{ width: 400, height: 400 }} loop autoplay></dotlottie-player>

                                <Text center style={{
                                    fontSize: 14,
                                    fontFamily: 'SourceSansProSemiBold'
                                }}>
                                    Researchist is a smart assistant for everyday tasks. It helps you manage your research papers, find new research papers, and collaborate with other researchers, helps in finding new ideas, assist you in your research writing and much more.
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>

        <CustomModal
            title="Forgot Password"
            width={'500px'} visible={showForgotPasswordModal} onClose={() => {
                setShowForgotPasswordModal(false);
            }}>
            <View center paddingH-20>
                <View centerH style={{
                    width: "100%",
                    maxWidth: 500,
                }}>
                    <View style={{
                        width: "100%",
                    }}>
                        {Label({ label: "Email" })}
                        <CustomTextField value={forgotEmail} onChange={(value) => {
                            setForgotEmail(value);
                        }} />
                    </View>
                    {!otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        <PrimaryButton label="Send OTP" disabled={sendingOTP} onPress={async () => {
                            if (!forgotEmail) {
                                if (Platform.OS === 'web') {
                                    message.success("Please enter email");
                                } else {
                                    Alert.alert("Please enter email");
                                }
                                return;
                            }

                            setSendingOTP(true);
                            const res = await api.customRoute("forgot-password", {
                                email: forgotEmail,
                            });

                            if (res.message) {
                                if (Platform.OS === 'web') {
                                    message.success(res.message);
                                } else {
                                    Alert.alert(res.message);
                                }
                            } else {
                                if (Platform.OS === 'web') {
                                    message.success("Something went wrong");
                                } else {
                                    Alert.alert("Something went wrong");
                                }
                            }
                            setSendingOTP(false);
                            setOtpSent(true);
                        }} />
                    </View>}
                    {otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        {Label({ label: "OTP" })}
                        <CustomTextField value={forgotOTP} onChange={(value) => {
                            setForgotOTP(value);
                        }} />
                    </View>}
                    {otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        {Label({ label: "New Password" })}
                        <CustomTextField type="password" value={forgotPassword} onChange={(value) => {
                            setForgotPassword(value);
                        }} />
                    </View>}
                    {otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        <PrimaryButton label="Reset Password" onPress={async () => {
                            if (!forgotOTP) {
                                if (Platform.OS === 'web') {
                                    message.success("Please enter OTP");
                                } else {
                                    Alert.alert("Please enter OTP");
                                }
                                return;
                            }

                            if (!forgotPassword) {
                                if (Platform.OS === 'web') {
                                    message.success("Please enter new password");
                                } else {
                                    Alert.alert("Please enter new password");
                                }
                                return;
                            }

                            if (forgotPassword.length < 6) {
                                if (Platform.OS === 'web') {
                                    message.success("Password should be atleast 6 characters long");
                                } else {
                                    Alert.alert("Password should be atleast 6 characters long");
                                }
                                return;
                            }

                            const res = await api.customRoute("reset-password", {
                                email: forgotEmail,
                                otp: forgotOTP,
                                password: forgotPassword,
                            })

                            if (res.message) {
                                if (Platform.OS === 'web') {
                                    message.success(res.message);
                                } else {
                                    Alert.alert(res.message);
                                }

                                if (res.success) {
                                    setShowForgotPasswordModal(false);
                                }
                            } else {
                                if (Platform.OS === 'web') {
                                    message.success("Something went wrong");
                                } else {
                                    Alert.alert("Something went wrong");
                                }
                            }
                        }} />
                    </View>}
                </View>
            </View>

        </CustomModal>
    </View >)
})

export default LoginScreen

const $container = {
    flex: 1,
}