import {
    DarkTheme,
    DefaultTheme,
    NavigationContainer,
} from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { StackScreenProps, createStackNavigator } from "@react-navigation/stack"
import { observer } from "mobx-react-lite"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { ActivityIndicator, DeviceEventEmitter, Dimensions, SafeAreaView, StatusBar, useColorScheme } from "react-native"
import Config from "./config"
import { RootStoreProvider, useStores } from "./models"
import ListingScreen from "./screens/MainScreens/CRUD/ListingScreen"
import FormScreen from "./screens/MainScreens/CRUD/FormScreen"
import EditScreen from "./screens/MainScreens/CRUD/EditScreen"
import { View, Text, Card, TouchableOpacity, Image } from "react-native-ui-lib"
import ModalScreen from "./screens/MainScreens/ModalScreen"
import { Platform } from 'react-native';
import { BrowserRouter as RouterWeb, Route, Routes, useLocation } from 'react-router-dom';
import MobileDrawer from "./mycomponents/MobileDrawer"
import { api, collectionsNames } from "./services/api"
import ReportScreen from "./screens/MainScreens/CRUD/ReportScreen"
import ReportScreenSingle from "./screens/MainScreens/CRUD/ReportScreenSingle"
import HomeScreen from "./screens/MainScreens/Custom/Internal_Pages/main/HomeScreen"
import PosScreen from "./screens/MainScreens/Custom/Internal_Pages/accounting/PosScreen"
import AccountingReports from "./screens/MainScreens/Custom/Internal_Pages/accounting/AccountingReports"
import OrganizationSettings from "./screens/MainScreens/Custom/Internal_Pages/main/OrganizationSettings"
import LoginScreen from "./screens/MainScreens/Custom/Internal_Pages/main/LoginScreen"
import ApprovalRequestScreen from "./screens/MainScreens/Custom/Internal_Pages/main/ApprovalRequests"
import { App } from "antd"
import BarcodePrintingScreen from "./screens/MainScreens/Custom/Internal_Pages/accounting/BarcodePrintingScreen"
import ItemTracingScreen from "./screens/MainScreens/Custom/Internal_Pages/accounting/ItemTracingScreen"
import { GlobalMasterStore } from "./models/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import OrganizationChartScreen from "./screens/MainScreens/Custom/Internal_Pages/hrms/OrganizationChart"
import CustomModalProvider from "./mycomponents/CustomModalProvider"
import FaceAuth from "./screens/MainScreens/Custom/Internal_Pages/main/FaceAuth/FaceAuth"
import { GestureHandlerRootView } from "react-native-gesture-handler"
import Home from "./screens/MainScreens/Custom/External_Pages/eComm/Home"
import Shop from "./screens/MainScreens/Custom/External_Pages/eComm/Shop"
import Product from "./screens/MainScreens/Custom/External_Pages/eComm/Product"
import LoginPhoneScreen from "./screens/MainScreens/Custom/Internal_Pages/main/LoginPhoneScreen"
import Checkout from "./screens/MainScreens/Custom/External_Pages/eComm/Checkout"
import { AutocompleteDropdownContextProvider } from 'react-native-autocomplete-dropdown'
import { Sidebar } from "./mycomponents/SideBar"
import ThankYou from "./screens/MainScreens/Custom/External_Pages/eComm/ThankYou"
import { Circle, Defs, LinearGradient, Path, Stop, Svg } from "react-native-svg"
import DailyActivityScreen from "./screens/MainScreens/Custom/Internal_Pages/taqwa/DailyActivityScreen"
import EventsScreen from "./screens/MainScreens/Custom/Internal_Pages/taqwa/EventsScreen"
import TrainingScreen from "./screens/MainScreens/Custom/Internal_Pages/taqwa/TrainingScreen"
import { GlobalLoaderStore } from "./models/GlobalLoaderStore"
import { CustomModal } from "./mycomponents/DynamicForm"
import MeetingScreen from "./screens/MainScreens/Custom/Internal_Pages/taqwa/MeetingScreen"
import ReportsScreen from "./screens/MainScreens/Custom/Internal_Pages/taqwa/Reports"
import { StatusBar as ExpoStatusBar } from 'expo-status-bar';
import NotificationScreen from "./screens/MainScreens/Custom/NotificationScreen"
import AttendanceScreen from "./screens/MainScreens/Custom/Internal_Pages/taqwa/AttendanceScreen"
import PrivacyPolicy from "./screens/MainScreens/Custom/External_Pages/eComm/PrivacyPolicy"
import ActivityScreen from "./screens/MainScreens/Custom/Internal_Pages/taqwa/ActivityScreen"
import SalesActivityScreen from "./screens/MainScreens/Custom/Internal_Pages/taqwa/SalesActivityScreen"
import GoalsScreen from "./screens/MainScreens/Custom/Internal_Pages/taqwa/GoalsScreen"
import EmployeeDocumentsScreen from "./screens/MainScreens/Custom/Internal_Pages/taqwa/EmployeeDocumentsScreen"
import SearchScreen from "./screens/MainScreens/Custom/Internal_Pages/main/SearchScreen"
import PaperListingScreen from "./screens/MainScreens/Custom/Internal_Pages/main/PaperListingScreen"
import ResearchHome from "./screens/MainScreens/Custom/Internal_Pages/main/ResearchHome"
import PaperChatScreen from "./screens/MainScreens/Custom/Internal_Pages/main/PaperChatScreen"
import TopicListingScreen from "./screens/MainScreens/Custom/Internal_Pages/main/TopicListingScreen"
import TempLoginScreen from "./screens/MainScreens/Custom/Internal_Pages/main/TempLoginScreen"
import OnboardingScreen from "./screens/MainScreens/Custom/Internal_Pages/main/OnboardingScreen"
import LibraryScreen from "./screens/MainScreens/Custom/Internal_Pages/main/LibraryScreen"
import ProfileScreenResearchist from "./screens/MainScreens/Custom/Internal_Pages/main/ProfileScreenResearchist"
import WorksScreen from "./screens/MainScreens/Custom/Internal_Pages/main/WorksScreen"
import WorkSingleScreen from "./screens/MainScreens/Custom/Internal_Pages/main/WorkSingleScreen"

const exitRoutes = Config.exitRoutes

const Stack = createNativeStackNavigator()

const GlobalErrors = () => {
    const { message } = App.useApp();

    const showError = (error) => {

    }

    const setOrg = async () => {
        if (Platform.OS == 'web') {
            const urlParams = new URLSearchParams(window.location.search);
            const token = await AsyncStorage.getItem("token");
            if (urlParams.get("faizan_org123") && token) {
                api.setTokenToAPI(token, urlParams.get("faizan_org123"));
            }
        }
    }

    useEffect(() => {
        api.addErrorInterceptor(showError);
        //get query params 
        setOrg();
    }, [])

    return <></>;
};

const GlobalLoading = (props) => {
    const loading = GlobalLoaderStore((state) => state.loading);

    return loading ? <View style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 9999999999,
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <ActivityIndicator size="large" color="#fff" />
    </View> : null;
}

// const UpdatePageTitle = () => {
//     useEffect(() => {
//         console.log("window.location.pathname", window.location.pathname);
//         const title = window.location.pathname.split("/").pop();

//         document.title = title;
//     }, [window.location.pathname]);

//     return <View>HIII</View>;
// }

const AppStack = observer(function AppStack() {
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);
    const setEmployee = GlobalMasterStore((state) => state.setEmployee);
    const setLocations = GlobalMasterStore((state) => state.setLocations);
    const tempLoggedIn = GlobalMasterStore((state) => state.tempLoggedIn);
    const setTempLoggedIn = GlobalMasterStore((state) => state.setTempLoggedIn);

    const token = GlobalMasterStore((state) => state.token);
    const organization_id = GlobalMasterStore((state) => state.globalData.organization_id);
    const employee_id = GlobalMasterStore((state) => state.globalData.employee_id);
    const intervalRef = React.useRef(null);

    const { modalStore: { getOpenModals, setOpenModal }, layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition } } = useStores();

    const [darkBg, setDarkBg] = useState(false);

    const myReloadFn = () => {
        window.location.reload();
    }

    useLayoutEffect(() => {
        DeviceEventEmitter.addListener('changeDarkBg', (data) => {
            setDarkBg(true);
        });

        if (Platform.OS == 'web') {
            const Mousetrap = require('mousetrap');

            Mousetrap.bind('esc esc esc esc esc', () => {
                myReloadFn()
            });

            Mousetrap.prototype.stopCallback = function (e, element, combo) {
                return false;
            }

            const $ = require('jquery');

            $("body").on("keydown", "input, textarea, select, button", function (e) {
                try {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        var self = $(this);
                        if (self.attr("aria-label") == "Primary Button") {
                            self.click();
                            return false;
                        }
                        var body = self.parents("body:eq(0)");
                        setTimeout(() => {
                            var focusable = body.find(`input,a,select,button,textarea,[aria-label="Primary Button"]`)
                            var ariaL = body.find(`[aria-label="Primary Button"]`)
                            if (self.attr("id")) {
                                self = $(`#${self.attr("id")}`);
                            }
                            var next = focusable.eq(focusable.index(self) + 1);
                            if (next.attr("aria-hidden") == "true") {
                                focusable.eq(0).focus();
                            } else if (next.attr("aria-label") == "Primary Button") {
                                next.click();
                            } else if (next.length) {
                                next.focus();
                            }
                        }, 0);
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        }
    }, []);

    const getPermission = async () => {
        console.log("Getting Permissions2");

        console.log("organization_id", organization_id);
        console.log("employee_id", employee_id);

        if (employee_id) {
            const res = await api.getPermissions({
                organization_id: organization_id,
                employee_id: employee_id
            })

            if (res) {
                if (res.locations) {
                    const locations = res.locations.map((location) => {
                        return {
                            "_id": location._id,
                            "name": location.name,
                            "address": location.address,
                            "latitude": location.latitude,
                            "longitude": location.longitude,
                        }
                    })

                    setLocations(locations)
                }

                const employee = {
                    _id: res.employee?._id,
                    name: res.employee?.name,
                    email: res.employee?.email,
                    contact_number: res.employee?.contact_number,
                    user_id: res.employee?.user_id,
                    role_id: res.employee?.role_id,
                    employee_code: res.employee?.employee_code,
                    account_id: res.employee?.account_id,
                    image: res.employee?.image,
                    enable_face_auth: res.employee?.enable_face_auth,
                    face_auth_image_url: res.employee?.face_auth_image_url,
                }

                setPermissions(res.permissions)
                setEmployee(employee)
            }
        }
    };

    useEffect(() => {
        getPermission();

        if (Platform.OS != 'web') {
            console.log("intervalRef.current", intervalRef.current);
            intervalRef.current = setInterval(() => {
                console.log("Getting Permissions");
                getPermission();
            }, 20000)
        }

        return () => {
            if (Platform.OS != 'web') {
                clearInterval(intervalRef.current);
            }
        }
    }, [organization_id, employee_id, token]);

    const routes = React.useMemo(() => {
        return collectionsNames.map(collection => (<>
            <Route key={collection.name} path={`/${collection.name}`} title={collection.name} Component={props => (
                <ListingScreen {...props} />
            )} />
            <Route key={collection.name} path={`/${collection.name}/New`} title={collection.name} Component={props => (
                <EditScreen {...props} />
            )} />
            <Route key={collection.name} path={`/${collection.name}/Edit`} Component={props => (
                <EditScreen {...props} />
            )} />
        </>))
    }, [collectionsNames]);

    const titleCheckInterval = React.useRef(null);

    useEffect(() => {
        if (Platform.OS == 'web') {
            titleCheckInterval.current = setInterval(() => {
                console.log("document.title", document.title);
                if (document.title != "Researchist") {
                    document.title = "Researchist";
                    console.log("document.title", document.title);
                    clearInterval(titleCheckInterval.current);
                }
            }, 50);
        }
    }, []);

    return <CustomModalProvider>
        <AutocompleteDropdownContextProvider>
            <View style={{
                flex: 1,
                paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
                position: 'relative',
                backgroundColor: Platform.OS == 'web' ? 'white' : 'white',
                // marginBottom: Platform.OS == 'ios' ? 40 : 0,
                // borderTopColor: '#29459c',
                // borderTopWidth: StatusBar.currentHeight || 48,
            }}>
                <ExpoStatusBar style="light" />
                <View style={{
                    backgroundColor: 'white',
                    zIndex: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: 100,
                }}></View>

                <View style={{
                    backgroundColor: 'white',
                    zIndex: 0,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: 100,
                }}></View>

                <View useSafeArea style={{
                    flex: 1,
                    //backgroundColor: darkBg ? '#242424' : 'red',
                    zIndex: 2
                }}>
                    {Platform.OS == 'web' ? <RouterWeb>
                        <App>
                            <View style={{
                                backgroundColor: darkBg ? '#f9f9f9' : '#f9f9f9',
                            }}>
                                <GlobalErrors />
                                <ModalScreen />

                                <View row spread flex style={{
                                    position: 'absolute',
                                    top: 0,
                                    width: '100%',
                                    height: '100%',
                                    opacity: 0.05,
                                }}>

                                    <div
                                        style={{
                                            background: `url("https://pub-ed4dcf9e4976449fa869f76b2ec53b5d.r2.dev/image.webp")`,
                                            height: "100%",
                                            width: "100%",
                                            backgroundSize: "45%"
                                        }}
                                    />

                                    {/* <View flex left style={{
                                        filter: "blur(75px)",
                                        opacity: 0.1
                                    }}>
                                        <Svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 65 65"
                                            fill="none"
                                            className="w-full h-full"
                                            preserveAspectRatio="none"
                                            style={{
                                                height: '100vh',
                                                position: 'relative',
                                                left: '10%'
                                            }}
                                        >
                                            <Circle cx={32.5} cy={32.5} r={32.5} fill="url(#a)" />
                                            <Defs>
                                                <LinearGradient
                                                    id="a"
                                                    x1={0}
                                                    x2={65}
                                                    y1={0}
                                                    y2={65}
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <Stop offset={0} stopColor="#7B6BE5"></Stop>
                                                    <Stop offset={1} stopColor="#6200EE"></Stop>
                                                </LinearGradient>
                                            </Defs>
                                        </Svg>
                                    </View>
                                    <View flex right style={{
                                        filter: "blur(75px)",
                                        opacity: 0.1
                                    }}>
                                        <Svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 65 65"
                                            fill="none"
                                            preserveAspectRatio="none"
                                            style={{
                                                height: '100vh',
                                                position: 'relative',
                                                right: '10%'
                                            }}
                                        >
                                            <Circle cx={32.5} cy={32.5} r={32.5} fill="url(#a2)" />
                                            <Defs>
                                                <LinearGradient
                                                    id="a2"
                                                    x1={0}
                                                    x2={65}
                                                    y1={0}
                                                    y2={65}
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <Stop offset={0} stopColor="#FF0266"></Stop>
                                                    <Stop offset={1} stopColor="#EB89A0"></Stop>
                                                </LinearGradient>
                                            </Defs>
                                        </Svg>
                                    </View> */}
                                </View>

                                {/* {!tempLoggedIn && <LoginScreen />} */}

                                <View flex row={Platform.OS == 'web' && getSideBarPosition == 'left'} style={{
                                    height: Dimensions.get('window').height,
                                }}>

                                    {/* {token && <SideBarCardComponent />} */}

                                    <Routes>
                                        {/* <Route element={<UpdatePageTitle />} /> */}

                                        {token ? (<>

                                            <Route path="/home" element={<SearchScreen />} />
                                            <Route path="/library" element={<LibraryScreen />} />
                                            <Route path="/user-onboarding" element={<OnboardingScreen />} />
                                            <Route path="/my-work" element={<WorksScreen />} />
                                            <Route path="/work-details" element={<WorkSingleScreen />} />
                                            {/* <Route path="/shop/product/:slug/:id" element={<Product />} />
                                            <Route path="/shop/products" element={<Shop />} />
                                            <Route path="/shop/checkout" element={<Checkout />} />
                                            <Route path="/shop/thank-you" element={<ThankYou />} /> */}
                                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                            <Route path="/" element={<SearchScreen />} />
                                            <Route path="/search-results" element={<PaperListingScreen />} />
                                            <Route path="/topic-suggestion" element={<TopicListingScreen />} />
                                            <Route path="/profile" element={<ProfileScreenResearchist />} />

                                            <Route path="/chat-with-paper" element={<PaperChatScreen />} />
                                            <Route path="/shop/ResearchHome" element={<ResearchHome />} />

                                            <Route path="/login" element={<LoginPhoneScreen />} />
                                            <Route path="/loginWithEmail" element={<LoginScreen />} />

                                            <Route path="/dashboard" element={<SearchScreen />} />
                                            <Route path="/dashboard" element={<SearchScreen />} />
                                            <Route path="/faceAuth" element={<FaceAuth />} />
                                            <Route path="/pos" element={<PosScreen />} />
                                            <Route path="/dailyActivity" element={<DailyActivityScreen />} />
                                            <Route path="/salesActivity" element={<SalesActivityScreen />} />
                                            <Route path={"AccountingReports"} element={<AccountingReports />} />
                                            <Route path={"HRMSReports"} element={<AccountingReports />} />
                                            <Route path={"TaskReports"} element={<AccountingReports />} />
                                            <Route path={"OrganizationSettings"} element={<OrganizationSettings />} />

                                            <Route path="/ApprovalRequests" element={<ApprovalRequestScreen />} />
                                            <Route path="/BarcodePrinting" element={<BarcodePrintingScreen />} />
                                            <Route path="/ItemTracing" element={<ItemTracingScreen />} />
                                            <Route path="/OrganizationChart" element={<OrganizationChartScreen />} />
                                            <Route path="/Reports" element={<ReportsScreen />} />

                                            {routes}

                                            <Route path={"report/:moduleName"} element={<ReportScreenSingle />} />

                                            <Route path="*" element={<SearchScreen />} />
                                        </>) : (<>
                                            <Route path="/login" element={<LoginScreen />} />
                                            <Route path="/loginWithEmail" element={<LoginScreen />} />
                                            <Route path="*" element={<LoginScreen />} />
                                        </>)}
                                    </Routes>
                                </View>
                            </View>
                        </App>
                    </RouterWeb> :
                        <>
                            <GlobalErrors />
                            {token ? (<MobileDrawer darkBg={darkBg} />) : null}
                            {/* <ModalScreen /> */}
                            <Stack.Navigator
                                screenOptions={{ headerShown: false, gestureEnabled: false }}
                                initialRouteName={token ? "dashboard" : "login"} // @demo remove-current-line
                            >
                                {token ? (
                                    <>
                                        <Stack.Screen name="dashboard" component={HomeScreen} />
                                        <Stack.Screen name="faceAuth" component={FaceAuth} />
                                        <Stack.Screen name="POS" component={PosScreen} />
                                        <Stack.Screen name="dailyActivity" component={DailyActivityScreen} />
                                        <Stack.Screen name="salesActivity" component={SalesActivityScreen} />
                                        <Stack.Screen name="EventsScreen" component={EventsScreen} />
                                        <Stack.Screen name="TrainingScreen" component={TrainingScreen} />
                                        <Stack.Screen name="MeetingScreen" component={MeetingScreen} />
                                        <Stack.Screen name="EmployeeDocumentsScreen" component={EmployeeDocumentsScreen} />
                                        <Stack.Screen name="GoalsScreen" component={GoalsScreen} />
                                        <Stack.Screen name="AttendanceScreen" component={AttendanceScreen} />
                                        <Stack.Screen name="ActivityScreen" component={ActivityScreen} />
                                        <Stack.Screen name="ItemTracing" component={ItemTracingScreen} />
                                        <Stack.Screen name="NotificationScreen" component={NotificationScreen} />

                                        {collectionsNames.map((collection, key) => {
                                            return <Stack.Screen key={key.toString()} name={collection.name} component={ListingScreen} />;
                                        })}

                                        <Stack.Screen name={"AccountingReports"} component={AccountingReports} />
                                        <Stack.Screen name="New" component={FormScreen} />

                                        {/* <Route path={"report/:moduleName"} element={<ReportScreenSingle />} /> */}
                                        <Stack.Screen name={"report"} component={ReportScreenSingle} />
                                    </>
                                ) : (
                                    <>
                                        <Stack.Screen name="login" component={LoginScreen} />
                                        <Stack.Screen name="loginWithEmail" component={LoginScreen} />
                                    </>
                                )}
                            </Stack.Navigator>
                        </>}
                </View>

                <GlobalLoading />

            </View>
        </AutocompleteDropdownContextProvider>
    </CustomModalProvider >
})

const SideBarCardComponent = (props) => {
    const { modalStore: { getOpenModals, setOpenModal }, layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition } } = useStores();

    let location = useLocation();
    const noDashboardPagesWeb = ["/home", "/shop/", "/login", "/loginWithEmail", "/faceAuth"];

    const [showSideBar, setShowSideBar] = useState(noDashboardPagesWeb.find((page) => location.pathname?.toLowerCase()?.includes(page?.toLowerCase())));

    useEffect(() => {
        if (Platform.OS == 'web') {
            if (noDashboardPagesWeb.find((page) => location.pathname?.toLowerCase()?.includes(page?.toLowerCase()))) {
                setShowSideBar(false);
            } else {
                setShowSideBar(true);
            }
        }
    }, [location]);

    return showSideBar ? <div style={{
        overflow: 'hidden',
    }}>
        <Card row={getSideBarPosition == 'top'} flex backgroundColor="#29459C" style={{
            width: isSideBarCollapsed ? 60 : getSideBarPosition == 'left' ? 250 : '100%',
            minWidth: isSideBarCollapsed ? 60 : getSideBarPosition == 'left' ? 250 : '100%',
            maxWidth: isSideBarCollapsed ? 60 : getSideBarPosition == 'left' ? 250 : '100%',
            borderRadius: 10,
            margin: 20,
            height: Dimensions.get('window').height - 40,
            marginRight: 0,
        }}>
            <View row marginH-20 marginT-20 style={{ minHeight: 26 }}>
                {getSideBarPosition == 'left' && <TouchableOpacity onPress={() => {
                    toggleSideBarCollapsed();
                }}>
                    {/* <Image source={{ uri: require('../assets/icons/menu.png') }} style={{ width: 20, height: 20, }} /> */}
                    <Svg
                        width={20}
                        height={20}
                        fill="none"
                        viewBox="0 0 32 32"
                    >
                        <Path
                            fill="#fff"
                            d="M2.667 24.004c0 .733.594 1.327 1.327 1.327h10.68a1.327 1.327 0 0 0 0-2.654H3.993c-.733 0-1.327.594-1.327 1.327ZM2.667 15.999c0 .733.594 1.327 1.327 1.327h24.013a1.327 1.327 0 1 0 0-2.654H3.994c-.733 0-1.327.594-1.327 1.327ZM3.994 9.32a1.327 1.327 0 0 1 0-2.654h16.013a1.327 1.327 0 0 1 0 2.654H3.994Z"
                        />
                    </Svg>
                </TouchableOpacity>}

                {!isSideBarCollapsed && <View flex centerV marginL-20>
                    <Image cover assetGroup={"images"} assetName="logo" style={{ width: 148, height: 40 }} />
                </View>}
            </View>

            <Sidebar />
        </Card>
    </div> : null;
}

export const AppNavigator = observer(function AppNavigator(props) {
    const colorScheme = useColorScheme()

    return (
        <NavigationContainer
            theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
            {...props}
        >
            <AppStack />
        </NavigationContainer>
    )
})
